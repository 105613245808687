#receipt-data {
  font-size: 12px;
  line-height: 18px;
  font-family: 'Ubuntu', sans-serif;
}
#receipt-data h2 {
  font-size: 16px;
}
#receipt-data td,
th,
tr,
table {
  border-collapse: collapse;
}
#receipt-data tr {border-bottom: 1px dashed #ddd;}
#receipt-data td,th {padding: 7px 0;width: 50%;}

#receipt-data table {width: 100%;}
#receipt-data tfoot tr th:first-child {text-align: left;}

#receipt-data .centered {
  text-align: center;
  align-content: center;
}
#receipt-data small{font-size:11px;}